import Navigation from "./components/navigation";
import Footer from "./components/footer";

function Privacy() {
  return (
    <div className="mx-auto max-w-5xl px-4 ">
      <Navigation />

      <div class="text-[#8a8f98] min-h-screen">
  <div class="max-w-4xl mx-auto px-6 py-16">
    <h1 class="text-[#f7f8f8] text-4xl font-semibold pb-8">Privacy Policy</h1>
    
    <p class="pb-6 text-lg tracking-tighter">
      This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
    </p>

    <p class="pb-12 text-lg tracking-tighter">
      We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
    </p>

    <h2 class="text-[#f7f8f8] text-2xl font-semibold pb-4">Interpretation and Definitions</h2>
    
    <h3 class="text-[#f7f8f8] text-xl font-semibold pb-3">Interpretation</h3>
    
    <p class="pb-6 text-lg tracking-tighter">
      The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
    </p>

    <h3 class="text-[#f7f8f8] text-xl font-semibold pb-3">Definitions</h3>
    
    <div class="pb-8">
      <ul class="list-none space-y-4">
        <li>
          <span class="text-[#f7f8f8] font-medium">Account</span> means a unique account created for You to access our Service or parts of our Service.
        </li>
        <li>
          <span class="text-[#f7f8f8] font-medium">Company</span> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Protoku BV, Walter Thijsstraat 7, 3500 Hasselt, Belgium.
        </li>
        <li>
          <span class="text-[#f7f8f8] font-medium">Country</span> refers to: Belgium
        </li>
        <li>
          <span class="text-[#f7f8f8] font-medium">Service</span> refers to the Applications and Website.
        </li>
        <li>
          <span class="text-[#f7f8f8] font-medium">Application</span> refers to Black Citadel, 100x.sh, or any other software program provided by the Company.
        </li>
        <li>
          <span class="text-[#f7f8f8] font-medium">Website</span> refers to Protoku, accessible from https://protoku.io
        </li>
      </ul>
    </div>

    <h2 class="text-[#f7f8f8] text-2xl font-semibold pb-4">Collecting and Using Your Personal Data</h2>
    
    <h3 class="text-[#f7f8f8] text-xl font-semibold pb-3">Types of Data Collected</h3>
    
    <h4 class="text-[#f7f8f8] text-lg font-semibold pb-2">Personal Data</h4>
    
    <p class="pb-4 text-lg tracking-tighter">
      While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
    </p>

    <ul class="list-disc pl-6 pb-6 space-y-2">
      <li>Email address</li>
      <li>First name and last name</li>
      <li>Usage Data</li>
      <li>Payment information</li>
    </ul>

    <h4 class="text-[#f7f8f8] text-lg font-semibold pb-2">Usage Data</h4>
    
    <p class="pb-8 text-lg tracking-tighter">
      Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
    </p>


    <h2 class="text-[#f7f8f8] text-2xl font-semibold pb-4">Contact Us</h2>
    
    <p class="pb-4 text-lg tracking-tighter">
      If you have any questions about this Privacy Policy, You can contact us:
    </p>

    <ul class="list-disc pl-6 pb-8 space-y-2">
      <li>By email: <a href="mailto:konnichiwa@protoku.io" class="text-[#f7f8f8] hover:underline">konnichiwa@protoku.io</a></li>
      <li>By visiting this page on our website: <a href="https://protoku.io/privacy" class="text-[#f7f8f8] hover:underline">https://protoku.io/privacy</a></li>
    </ul>

    <h2 class="text-[#f7f8f8] text-2xl font-semibold pb-4">Company Information</h2>
    
    <div class="text-lg tracking-tighter space-y-1">
      <p>Protoku BV</p>
      <p>Walter Thijsstraat 7</p>
      <p>3500 Hasselt</p>
      <p>Belgium</p>
      <p class="pt-4">BTW: BE0686633603</p>
      <p>IBAN: BE66 1030 5301 7743</p>
      <p>BIC: NICA BE BB</p>
    </div>

    <div class="border-t border-white/10 mt-16 pt-8">
      <p class="text-sm/6 text-gray-400">
        Last updated: November 6, 2024
      </p>
    </div>
  </div>
</div>

      <Footer />
    </div>
  );
}

export default Privacy;
