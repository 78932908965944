import Navigation from "./components/navigation";
import Footer from "./components/footer";
import HeadingSection from "./components/heading-section";
import HeadingTitle from "./components/heading-title";

function Terms() {
  return (
    <div className="mx-auto max-w-5xl px-4 ">
      <Navigation />

      <div class="text-[#8a8f98] min-h-screen">
  <div class="px-6 py-16">
    <HeadingSection
      title="Terms & Conditions"
      anchor="terms"
      className="bg-purple-500"
    />
    <HeadingTitle>Terms & Conditions</HeadingTitle>
    
    <p class="text-[#8a8f98] pb-16 text-lg sm:text-xl tracking-tighter font-medium">
      Please read these terms and conditions carefully before using Our Service.
    </p>

    <HeadingTitle>Definitions</HeadingTitle>
    
    <div class="bg-[#101010] p-8 sm:border sm:border-[#2a2a2a] mb-16">
      <ul class="space-y-4">
        <li>
          <span class="text-[#f7f8f8]">Company</span>: Protoku BV (referred to as "the Company", "We", "Us" or "Our"), Walter Thijsstraat 7, 3500 Hasselt, Belgium.
        </li>
        <li>
          <span class="text-[#f7f8f8]">Services</span>: Our software applications including Black Citadel, 100x.sh, and any related services.
        </li>
        <li>
          <span class="text-[#f7f8f8]">Subscription</span>: The recurring payment plan that provides access to our Services.
        </li>
        <li>
          <span class="text-[#f7f8f8]">User</span>: The individual or entity accessing our Services (referred to as "You" or "Your").
        </li>
      </ul>
    </div>

    <HeadingTitle>Service Terms</HeadingTitle>
    
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-16">
      <div class="bg-[#101010] p-8 sm:border sm:border-[#2a2a2a]">
        <h4 class="text-[#f7f8f8] text-lg font-semibold pb-4">Usage Rights</h4>
        <ul class="space-y-2">
          <li>Access to contracted services</li>
          <li>Technical support</li>
          <li>Regular updates</li>
          <li>Data protection</li>
        </ul>
      </div>
      
      <div class="bg-[#101010] p-8 sm:border sm:border-[#2a2a2a]">
        <h4 class="text-[#f7f8f8] text-lg font-semibold pb-4">User Obligations</h4>
        <ul class="space-y-2">
          <li>Account security</li>
          <li>Proper usage</li>
          <li>Payment responsibilities</li>
          <li>Compliance with laws</li>
        </ul>
      </div>
    </div>

    <HeadingTitle>Subscription Terms</HeadingTitle>
    
    <div class="bg-[#101010] p-8 sm:border sm:border-[#2a2a2a] mb-16">
      <div class="space-y-6">
        <div>
          <h4 class="text-[#f7f8f8] text-lg font-semibold pb-2">Billing</h4>
          <p>Subscriptions are billed in advance on a recurring basis. You authorize us to charge your provided payment method for all purchased Services.</p>
        </div>
        
        <div>
          <h4 class="text-[#f7f8f8] text-lg font-semibold pb-2">Cancellation</h4>
          <p>You may cancel your subscription at any time. Upon cancellation, you'll retain access until the end of your current billing period.</p>
        </div>
        
        <div>
          <h4 class="text-[#f7f8f8] text-lg font-semibold pb-2">Refunds</h4>
          <p>Refunds are handled on a case-by-case basis and at the sole discretion of Protoku BV.</p>
        </div>
      </div>
    </div>

    <HeadingTitle>Limitations</HeadingTitle>
    
    <div class="bg-[#101010] p-8 sm:border sm:border-[#2a2a2a] mb-16">
      <p class="pb-4">The Service is provided "AS IS" and "AS AVAILABLE" without warranties of any kind, either express or implied, including but not limited to:</p>
      <ul class="space-y-2 pb-4">
        <li>Merchantability</li>
        <li>Fitness for a particular purpose</li>
        <li>Non-infringement</li>
      </ul>
      <p>Our liability shall be limited to the amount paid by you for the Service in the preceding 12 months.</p>
    </div>

    <HeadingTitle>Contact Information</HeadingTitle>
    
    <div class="bg-[#101010] p-8 sm:border sm:border-[#2a2a2a] mb-16">
      <p class="pb-4">For questions about these Terms & Conditions, please contact us:</p>
      
      <div class="space-y-2 pb-8">
        <p>Email: <a href="mailto:konnichiwa@protoku.io" class="text-[#f7f8f8] hover:underline">konnichiwa@protoku.io</a></p>
        <p>Website: <a href="https://protoku.io/terms" class="text-[#f7f8f8] hover:underline">https://protoku.io/terms</a></p>
      </div>
      
      <div>
        <p class="text-[#f7f8f8] font-medium pb-2">Company Details:</p>
        <p>Protoku BV</p>
        <p>Walter Thijsstraat 7</p>
        <p>3500 Hasselt</p>
        <p>Belgium</p>
        <p class="pt-4">BTW: BE0686633603</p>
        <p>IBAN: BE66 1030 5301 7743</p>
        <p>BIC: NICA BE BB</p>
      </div>
    </div>

    <div class="border-t border-white/10 pt-8">
      <p class="text-sm/6 text-gray-400">
        Last updated: November 6, 2024
      </p>
    </div>
  </div>
</div>

      <Footer />
    </div>
  );
}

export default Terms;
